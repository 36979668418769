<template>
  <div class="image-component"
       v-on:click="$openPopupImage(params)">
    <div class="image-component__wrapper">
      <img class="image-component__image"
           :src="params"
           alt/>
      <div class="image-component__full">
        <Icon class="image-component__icon"
              viewport="0 0 24 24"
              xlink="loupe"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageComponent',
  props: {
    params: {},
  },
};
</script>
